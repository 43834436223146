import List from 'list.js';
import { filterableTable } from './filterable_table';

const initOrderStatusTable = () => {
    const options = {
        valueNames: [
            'id',
            'full_number',
            'created_at',
            'company_name',
            'client_reference',
            'status',
            'net_total',
            'shipping_cost',
            { name: 'show_url', attr: 'href' },
        ],
        item: (values) => `
            <tr>
                <td>
                    <a class="show_url full_number link" href=""></a>
                </td>
                <td class="created_at"></td>
                <td class="company_name"></td>
                <td class="client_reference"></td>
                <td class="status"></td>
                <td class="net_total"></td>
                <td class="shipping_cost"></td>
                <td class="action has-text-right nowrap">
                    <a class="action_show_url link is-small" href="${values.show_url}">
                        <span class="icon"><img src="build/images/edit.svg" /></span>
                    </a>
                </td>
            </tr>
        `,
    };

    const usersList = new List('order-status-table', options);

    const table = filterableTable(usersList, '/order-status/list');

    table.addFilter({
        name: 'term',
        className: 'order-search',
    });

    table.addDateFilter({
        name: 'created_at',
        className: 'order-date',
        options: { type: 'date', dateFormat: 'dd-MM-yyyy', showFooter: false },
    });
};


document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('order-status-table')) {
        initOrderStatusTable();
    }
});
