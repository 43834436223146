import List from 'list.js';
import { filterableTable } from './filterable_table';

const initSalesToolsTable = () => {
    const pageData = window.__PAGE_DATA__;

    const options = {
        valueNames: [
            'id',
            'active',
            'title',
            'media',
            'link',
            { name: 'link', attr: 'href' },
            'created_at',
        ],
        item: (values) => `
            <tr>                
                <td class="id"></td>
                <td class="has-text-centered">${values.active ?
        '<i class="fa fa-check" />' :
        '<i class="fa fa-times" />'
}</td>
                
                <td><strong>${values.title}</strong></td>    
                <td>
                    ${values.media ?
        `<a href="${values.media.download_url}" download="${values.media.file_name}" target="_blank">
                            <i class="fas fa-download" aria-hidden="true"></i>
                        </a>` :
        ''
}
                </td>            
                <td>
                    ${values.link ?
        `<a href="${values.link}" target="_blank">
                            <i class="fas fa-link" aria-hidden="true"></i>
                        </a>` :
        ''
}
                </td>
                <td class="created_at"></td>
                <td class="action has-text-right nowrap">
                    <a class="button action_show_url is-link is-small" href="${values.edit_url}">
                        <span class="icon">
                            <i class="fas fa-edit"></i>
                        </span>
                    </a>  
                    <form
                        action="${values.delete_url}"
                        method="POST" class="is-inline"
                        onsubmit="return confirm('${pageData.lang_delete_confirmation}');">
                        <input type="hidden" name="_method" value="delete">
                        <input type="hidden" name="_token" value="${pageData.csrfToken}" />

                        <button type="submit" class="button is-link is-small is-light is-danger">
                            <span class="icon">
                                <i class="fas fa-trash"></i>
                            </span>                                
                        </button>
                    </form>
                </td>
            </tr>
        `,
    };

    const list = new List('sales-tools-table', options);

    const table = filterableTable(list, '/mycfe/admin/sales-tools/list');

    table.addFilter({
        name: 'term',
        className: 'sales-tool-search',
    });
};

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('sales-tools-table')) {
        initSalesToolsTable();
    }
});
